import { Injectable } from '@angular/core';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { Data, DataState, DataStore } from '@app/DataStore';
import {
  AdvanceFullDetail,
  Agent,
  ApplicationListProjection,
  ApplicationListProjectionExtension,
  Customer,
  CustomerLoanProjection,
  LoanReadyApplication,
  Partner,
  PartnerPaidCommission,
  SecondaryApplicant
} from '@app/core/proxy/Read/models';
import {
  Announcement,
  OriginationBankAccount,
  PrimaryApplicant,
  PrimaryApplicantAndSecondaryApplicantRequested,
  RegisterCustomerRequest
} from '../proxy/Write/models';
import { OriginationWriteApi } from '../proxy/Write/originationWriteApi';
import { CSVExportType } from '@app/shared/components/export-to-csv/export-to-csv.component';
// import { ErrorHandlerService } from './application-insights.service';

@Injectable()
export class UserService {
  constructor(private readApi: OriginationReadApi, private writeApi: OriginationWriteApi) {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  setPrimaryAccount(applicationId: string, params: { body: OriginationBankAccount }) {
    params.body.isPrimary = true;
    return this.writeApi
      .setPrimaryAccount(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  removePartnerExternalLink(applicationId: string, params: { partnerId: string }) {
    return this.writeApi
      .removePartnerExternalLink(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  removeAgentExternalLink(applicationId: string, params: { agentId: string }) {
    return this.writeApi
      .removeAgentExternalLink(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  addCustomer(params: { body: RegisterCustomerRequest }) {
    return this.writeApi
      .addCustomer(params)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  updateLoginStatus(params: { applicationId: string; status: boolean }) {
    return this.writeApi
      .updateLoginStatus(params)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  sendLoginOtp(params: { email: string }) {
    return this.writeApi
      .sendLoginOtp(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  sendAddAccountOtp(params: { email: string }) {
    return this.writeApi
      .sendAddAccountOtp(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  setCustomerConnection(customerId: string, connectionId: string) {
    return this.writeApi
      .setCustomerConnection(customerId, connectionId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  addPrimaryApplicantDetails(
    applicationId: string,
    primaryApplicantAndSecondaryRequest: PrimaryApplicantAndSecondaryApplicantRequested
  ) {
    this.setApplicantId(null);
    return this.writeApi
      .addPrimaryApplicantDetails(applicationId, primaryApplicantAndSecondaryRequest)
      .then(res => {
        this.setApplicantId(res.body);
        return res.body;
      })
      .catch(err => {
        this.setApplicantId(null);
        return null;
      });
  }

  addSecondaryApplicantDetails(applicationId: string, applicant: PrimaryApplicant) {
    return this.writeApi
      .addSecondaryApplicantDetails(applicationId, applicant)
      .then(res => {
        let applicantId = res.body;

        if (!applicantId) {
          applicantId = res._response.bodyAsText;
        }
        this.setApplicantId(applicantId);
        return DataStore.applicantId;
      })
      .catch(err => {
        return null;
      });
  }

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  loadPartnerAnnouncements(partnerId: string) {
    this.setPartnerAnnouncements({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerAnnouncements(partnerId)
      .then(res => {
        this.setPartnerAnnouncements({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setPartnerAnnouncements({ data: null, state: DataState.Error });
        return null;
      });
  }

  setPartnerAnnouncements(data: Data<Announcement[]>) {
    DataStore.partnerData.announcements = data;
  }

  loadAgentAnnouncements(agentId: string) {
    this.setAgentAnnouncements({ data: null, state: DataState.Loading });
    return this.readApi
      .getAgentAnnouncements(agentId)
      .then(res => {
        this.setAgentAnnouncements({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentAnnouncements({ data: null, state: DataState.Error });
        return null;
      });
  }

  setAgentAnnouncements(data: Data<Announcement[]>) {
    DataStore.agentData.announcements = data;
  }

  getPrimaryAccount(applicantId: string) {
    return this.readApi
      .getPrimaryAccount(applicantId)
      .then(res => res)
      .catch(err => {
        return null;
      });
  }
  getMobileNumber(email: string) {
    return this.readApi
      .getMobileNumberByEmail({ email: email })
      .then(res => {
        return res;
      })
      .catch(err => {
        // this.errorHandlerService.handleError(err);
        return null;
      });
  }

  getStartupConfig() {
    return this.readApi
      .getStartupConfig()
      .then(res => res)
      .catch(err => {
        return null;
      });
  }

  getPrimaryApplicant(applicantId: string) {
    return this.readApi
      .getPrimaryApplicant(applicantId)
      .then(res => res)
      .catch(err => {
        return null;
      });
  }

  // isApplicantApproved(applicantId: string, applicantType: string) {
  //   return this.readApi
  //     .isApplicantApproved({ applicantId: applicantId, applicantType: applicantType })
  //     .then(res => {
  //       return res.body;
  //     })
  //     .catch(err => {
  //       return null;
  //     });
  // }

  loadPartnerById(id: string) {
    this.setPartner(null);
    return this.readApi
      .getPartner(id)
      .then(res => {
        this.setPartner(res);
        return res;
      })
      .catch(err => {
        this.setPartner(null);
        return null;
      });
  }

  loadPartnerApplicationList(id: string) {
    this.setPartnerApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerApplicationList({ partnerId: id, showCancel: true, cancelledDays: 21 })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setPartnerApplicationList({ data: res.data, state: DataState.Loaded });
      })
      .catch(err => {
        this.setPartnerApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadPartnerCompleteApplicationList(partnerId: string) {
    this.setPartnerCompletedApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerCompletedApplications({ partnerId: partnerId })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setPartnerCompletedApplicationList({ data: res.data, state: DataState.Loaded });
      })
      .catch(err => {
        this.setPartnerCompletedApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadPartnerNotCompleteApplicationList(id: string) {
    this.setPartnerNotCompleteApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerAllApplications({ partnerId: id })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setPartnerNotCompleteApplicationList({ data: res.data, state: DataState.Loaded });
      })
      .catch(err => {
        this.setPartnerNotCompleteApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadPartnerCancelledApplicationList(partnerId: string, cancelledDays = 21) {
    this.setPartnerCancelledApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerCancelledApplications({ partnerId: partnerId, cancelledDays: cancelledDays })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setPartnerCancelledApplicationList({ data: res.data, state: DataState.Loaded });
      })
      .catch(err => {
        this.setPartnerCancelledApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadPartnerHomepageData(id: string) {
    return Promise.all([
      this.loadPartnerById(id),
      this.loadPartnerCommission(id),
      this.loadPartnerCompleteApplicationList(id),
      this.loadPartnerNotCompleteApplicationList(id),
      this.loadPartnerCancelledApplicationList(id),
      this.loadAdvanceExtendedSummaryOnPartner(id)
    ]);
  }

  loadPartnerHomepageCompleteData(id: string, cancelledDays: number = 21) {
    return Promise.all([this.loadPartnerCancelledApplicationList(id, cancelledDays)]);
  }

  loadCustomer(customerId: string) {
    this.setCustomer(null);
    return this.readApi
      .getCustomer(customerId)
      .then(res => {
        this.setCustomer(res);
        return res;
      })
      .catch(err => {
        this.setCustomer(null);
        return null;
      });
  }

  loadSecondaryApplicant(applicationId: string) {
    this.setSecondaryApplicant(null);
    return this.readApi
      .getSecondaryApplicant(applicationId)
      .then(res => {
        this.setSecondaryApplicant(res);
        if (!DataStore.applicantId) {
          this.setApplicantId(DataStore.secondaryApplicant.id);
        }
        return res;
      })
      .catch(err => {
        this.setSecondaryApplicant(null);
        return null;
      });
  }

  loadCustomerLoans(customerId: string) {
    this.setCustomerLoans(null);
    return this.readApi
      .getLoansOnCustomerId(customerId, { take: 30, timeout: 60000 })
      .then(res => {
        this.setCustomerLoans(res.data);
        return res.data;
      })
      .catch(err => {
        this.setCustomerLoans(null);
        return null;
      });
  }

  setCustomerReadyApplications(data: LoanReadyApplication[]) {
    DataStore.customerData.readyApplications = data;
  }

  loadCustomerReadyApplications(customerId: string) {
    this.setCustomerReadyApplications(null);
    return this.readApi
      .getLoanReadyApplicationsOnCustomerId(customerId, { take: 3000, timeout: 60000 })
      .then(res => {
        this.setCustomerReadyApplications(res.data);
        return res.data;
      })
      .catch(err => {
        this.setCustomerReadyApplications(null);
        return null;
      });
  }

  loadCustomerForHomePage(id: string) {
    return Promise.all([this.loadCustomer(id), this.loadCustomerLoans(id), this.loadCustomerReadyApplications(id)]);
  }

  loadPrimaryApplicant(applicationId: string) {
    return this.readApi
      .getPrimaryApplicant(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  validateSecondaryEmail(applicationId: string, email: string) {
    return this.readApi
      .validateSecondaryEmail({ applicationId: applicationId, email: email })
      .then(res => {
        return res.value;
      })
      .catch(err => {
        return null;
      });
  }

  validateSecondaryMobile(applicationId: string, mobile: string) {
    return this.readApi
      .validateSecondaryMobile({ applicationId: applicationId, mobile: mobile })
      .then(res => {
        return res.value;
      })
      .catch(err => {
        return null;
      });
  }

  loadPartnerCommission(partnerId: string) {
    this.setPartnerCommission(null);
    return this.readApi
      .getPartnerCommissionsOnBusiness(partnerId)
      .then(res => {
        res.forEach(item => {
          if (item.fundedAmount < 0) {
            item.fundedAmount = 0;
          }
          if (item.amount < 0) {
            item.amount = 0;
          }
        });
        this.setPartnerCommission(res);
        return res;
      })
      .catch(err => {
        this.setPartnerCommission(null);
        return null;
      });
  }

  loadAgent(agentId: string) {
    this.setAgent(null);
    return this.readApi
      .getAgent(agentId)
      .then(res => {
        this.setAgent(res);
        return res;
      })
      .catch(err => {
        this.setAgent(null);
        return null;
      });
  }

  loadAgentHomepageData(id: string) {
    return Promise.all([
      this.loadAgentAndPartner(id),
      this.loadAdvanceExtendedSummaryOnAgent(id),
      this.loadAgentNotCompleteApplicationList(id),
      this.loadAgentCompletedApplicationList(id),
      this.loadAgentCancelledApplicationList(id)
    ]);
  }

  loadAgentAndPartner(id: string) {
    this.loadAgent(id).then(res => {
      if (res && res.partnerId) {
        this.loadPartnerById(res.partnerId);
      }
    });
  }

  loadAgentNotCompleteApplicationList(agentId: string) {
    this.setAgentNotCompleteApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getAgentAllApplications({ agentId: agentId })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setAgentNotCompleteApplicationList({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentNotCompleteApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadAgentCompletedApplicationList(agentId: string) {
    this.setAgentCompletedApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getAgentCompletedApplications({ agentId: agentId })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setAgentCompletedApplicationList({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentCompletedApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadAgentCancelledApplicationList(agentId: string, cancelledDays = 21) {
    this.setAgentCancelledApplicationList({ data: null, state: DataState.Loading });
    return this.readApi
      .getAgentCancelledApplications({ agentId: agentId, cancelledDays: cancelledDays })
      .then(res => {
        res.data.forEach(item => {
          if (item.availableFunds < 0) {
            item.availableFunds = 0;
          }
          if (item.currentBalance < 0) {
            item.currentBalance = 0;
          }
          if (item.totalAmountValue < 0) {
            item.totalAmountValue = 0;
          }
        });
        this.setAgentCancelledApplicationList({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentCancelledApplicationList({ data: null, state: DataState.Error });
        return null;
      });
  }

  getAgent(agentId: string) {
    this.setAgent(null);

    return this.readApi
      .getAgent(agentId)
      .then(res => {
        this.setAgent(res);
        return res;
      })
      .catch(err => {
        this.setAgent(null);
        return null;
      });
  }

  loadAgents(partnerId: string, showDeleted: boolean) {
    this.setAgents({ data: null, state: DataState.Loading });

    return this.readApi
      .getAgents(partnerId, { showDeleted: showDeleted })
      .then(res => {
        this.setAgents({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgents({ data: null, state: DataState.Error });
        return null;
      });
  }

  searchAgents(partnerId: string, search: string) {
    return this.readApi
      .searchAgents(partnerId, { search: search })
      .then(res => {
        return res;
      })
      .catch(err => {
        return [];
      });
  }

  loadAdvanceExtendedSummaryOnAgent(agentId: string) {
    this.setAgentAdvances({ data: null, state: DataState.Loading });
    return this.readApi
      .advanceExtendedSummaryOnAgent(agentId)
      .then(res => {
        res.data.forEach(item => {
          if (item.amountValue < 0) {
            item.amountValue = 0;
          }
        });
        this.setAgentAdvances({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentAdvances({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadAdvanceExtendedSummaryOnPartner(partnerId: string) {
    this.setPartnerAdvances({ data: null, state: DataState.Loading });

    return this.readApi
      .advanceExtendedSummaryOnPartner(partnerId)
      .then(res => {
        res.data.forEach(item => {
          if (item.amountValue < 0) {
            item.amountValue = 0;
          }
        });
        this.setPartnerAdvances({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setPartnerAdvances({ data: null, state: DataState.Error });
        return null;
      });
  }

  addUpdateAgentOnApplication(applicationId: string, agentId: string) {
    return this.writeApi
      .addUpdateAgentOnApplication(applicationId, { agentId: agentId })
      .then(res => {
        return res;
      })
      .catch(err => {
        return [];
      });
  }

  downloadAdvanceExtendedSummaryOnAgentFile(agentId: string, params: CSVExportType) {
    return this.readApi
      .downloadAdvanceExtendedSummaryOnAgentFile(agentId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  downloadAdvanceExtendedSummaryOnPartnerFile(partnerId: string, params: CSVExportType) {
    return this.readApi
      .downloadAdvanceExtendedSummaryOnPartnerFile(partnerId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  /* loadPartnerOverview(id: string) {
  return this.readApi.overview({ partnerId: id }).then(overview => {
    DataStore.partnerData.overview = overview;
  });
  }*/

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setAgentNotCompleteApplicationList(data: Data<ApplicationListProjection[]>) {
    DataStore.agentData.notCompleteApplicationList = data;
  }

  setAgentCompletedApplicationList(data: Data<ApplicationListProjection[]>) {
    DataStore.agentData.completedApplicationList = data;
  }

  setAgentCancelledApplicationList(data: Data<ApplicationListProjection[]>) {
    DataStore.agentData.cancelledApplicationList = data;
  }

  setAgentAdvances(data: Data<AdvanceFullDetail[]>) {
    DataStore.agentData.advances = data;
  }

  setAgent(data: Agent) {
    DataStore.agent = data;
  }

  setAgents(data: Data<Agent[]>) {
    DataStore.agents = data;
  }

  setPartnerCommission(data: PartnerPaidCommission[]) {
    DataStore.partnerData.commission = data;
  }

  setApplicantId(data: string) {
    DataStore.applicantId = data;
  }

  setCustomerLoans(data: CustomerLoanProjection[]) {
    DataStore.customerData.loans = data;
  }

  setSecondaryApplicant(data: SecondaryApplicant) {
    DataStore.secondaryApplicant = data;
  }

  setCustomer(data: Customer) {
    DataStore.customer = data;
  }

  setPartnerApplicationList(data: Data<ApplicationListProjection[]>) {
    DataStore.partnerData.applicationList = data;
  }

  setPartnerCompletedApplicationList(data: Data<ApplicationListProjectionExtension[]>) {
    DataStore.partnerData.completedApplicationList = data;
  }

  setPartnerNotCompleteApplicationList(data: Data<ApplicationListProjectionExtension[]>) {
    DataStore.partnerData.notCompleteApplicationList = data;
  }

  setPartnerCancelledApplicationList(data: Data<ApplicationListProjectionExtension[]>) {
    DataStore.partnerData.cancelledApplicationList = data;
  }

  setPartnerAdvances(data: Data<AdvanceFullDetail[]>) {
    DataStore.partnerData.advances = data;
  }

  resetPartner() {
    DataStore.partner = null;
  }

  setPartner(data: Partner) {
    DataStore.partner = data;
  }

  resetPartnerData() {
    this.resetPartner();

    DataStore.partnerData = {
      applicationList: { data: null, state: DataState.NotLoaded },
      commission: null,
      partnerCommunications: { data: null, state: DataState.NotLoaded },
      hasNewCommunication: false,
      completeApplicationList: { data: null, state: DataState.NotLoaded },
      notCompleteApplicationList: { data: null, state: DataState.NotLoaded },
      advances: { data: null, state: DataState.NotLoaded }
    };
  }

  resetCustomer() {
    DataStore.customer = null;
  }

  resetCustomerData() {
    DataStore.customerData.loans = null;
    DataStore.customerData.readyApplications = null;
    DataStore.customerData.displayedAdvance = {
      skipRepaymentDetails: null,
      repaymentDetails: null
    };
  }
}
