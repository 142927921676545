import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataStore } from '@app/DataStore';
import { OriginationWriteApi } from '@app/core/proxy/Write/originationWriteApi';
import { ApplicationService } from '@app/core/services/application.service';
import { fadeInOut } from '@app/shared/animation/animations';
import { CodeInputComponent } from 'angular-code-input';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInOut]
})
export class ForgotPasswordComponent implements OnInit {
  isInit = true;
  forgotPasswordForm: FormGroup;
  showModal = false;
  // isLoading = false;
  isProcessing = false;
  isSmsLogin = false;
  timeDisplay: string;
  interval;
  timeLeft: number = 20;
  contactUsUrl = `${window['appConfig']['contactUsUrl']}`;
  failed = false;
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  constructor(
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private writeApi: OriginationWriteApi,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  get isLoading() {
    return DataStore.isPageLoading;
  }

  ngOnInit(): void {
    this.initForm();

    this.startTimer();
    this.isInit = false;
  }

  sendCode() {
    if (this.forgotPasswordForm.valid) {
      this.showModal = true;
    }
  }

  forgotPassword() {
    // this.isLoading = true;
    DataStore.isPageLoading = true;
    this.writeApi
      .sendResetPasswordOtp({ email: this.forgotPasswordForm.value.email })
      .then(_ => (this.isSmsLogin = true))
      .catch(_ => {
        this.toastrService.error('Failed to send Code.');
      })
      .finally(() => (DataStore.isPageLoading = false));
  }

  onCodeCompleted(code: string) {
    this.failed = false;
    DataStore.isPageLoading = true;
    // TODO : new api validate the otp, returns a bool
    const urlInfo = {
      code,
      email: this.forgotPasswordForm.value.email
    };
    this.applicationService
      .validateOtp(code)
      .then(res => {
        if (res) {
          localStorage.setItem('userInfo', JSON.stringify(urlInfo));
          this.router.navigate(['/change-password']);
        } else {
          // this.toastrService.error('Code did not match. Try again.');
          this.failed = true;
        }
      })
      .catch(_ => {
        // this.toastrService.error('Code did not match. Try again.');
        this.failed = true;
      })
      .finally(() => (DataStore.isPageLoading = false));
  }

  resend() {
    if (!this.forgotPasswordForm.value.email) {
      this.isSmsLogin = false;
    }
    this.forgotPassword();
    this.codeInput.reset();
    this.timeLeft = 20;
    this.startTimer();
    this.failed = false;
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.timeDisplay = new Date(this.timeLeft * 1000).toISOString().substring(14, 19);
      }
    }, 1000);
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        ]
      ]
    });
  }

  loginWithPassword() {
    localStorage.setItem('loginClick', JSON.stringify(true));
  }
}
