<div class="container-fluid login-page py-5 h-100">
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex flex-column gap-smallest align-items-center">
      <img src="assets/icons/Logo.svg" alt="logo" class="logo" />
      <h1>Change Password</h1>
      <h3>Insert your new password</h3>
    </div>
    <div class="d-flex flex-column align-items-center gap-small">
      <form
        novalidate
        (ngSubmit)="changePassword()"
        [formGroup]="passwordForm"
        class="d-flex flex-column align-items-center gap-medium w-100"
      >
        <div class="row justify-content-center w-100">
          <div class="col-12 col-sm-6 col-lg-4">
            <input
              data-lpignore="true"
              type="password"
              class="form-control bg-transparent py-4 maxWidth"
              placeholder="New Password"
              formControlName="newpassword"
              autocomplete="new-password"
            />
            <div
              class="text-danger position-absolute f-12"
              *ngIf="passwordForm.controls.newpassword.touched && passwordForm.controls.newpassword.errors"
            >
              <span *ngIf="passwordForm.controls.newpassword.errors.required">Password is required</span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center w-100">
          <div class="col-12 col-sm-6 col-lg-4">
            <input
              data-lpignore="true"
              type="password"
              class="form-control bg-transparent py-4 maxWidth"
              placeholder="Confirm Password"
              formControlName="confirmpassword"
              autocomplete="confirm-password"
            />
            <div class="text-danger position-absolute f-12">
              <span
                *ngIf="
                  passwordForm.controls.confirmpassword.touched &&
                  passwordForm.controls.confirmpassword.errors &&
                  passwordForm.controls.confirmpassword.errors.required
                "
                >Confirm password is required</span
              >
              <span
                *ngIf="
                  passwordForm.controls.newpassword.touched &&
                  passwordForm.invalid &&
                  passwordForm.errors &&
                  passwordForm.errors.notSame &&
                  !(
                    passwordForm.controls.confirmpassword.errors &&
                    passwordForm.controls.confirmpassword.errors.required
                  )
                "
              >
                Passwords do not match
              </span>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="btn btn-primary rounded-pill d-flex align-items-center gap-smallest"
            [disabled]="passwordForm.invalid || isLoading"
          >
            <span class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>Set new password
          </button>
        </div>
      </form>

      <p class="plexe-dark-blue text-center m-0">Remember your old password?</p>
      <a routerLink="/login-sms">Login with SMS</a>
    </div>
    <div class="text-center d-flex flex-column">
      <p>If you need help</p>
      <a [href]="contactUsUrl" target="_blank">Contact us <i class="bi bi-caret-right-fill"></i></a>
    </div>
  </div>
</div>
