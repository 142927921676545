<!-- <div class="app-header" [ngClass]="{ 'system-message': systemMessage }">
  <nav class="navbar navbar-expand-lg landing-header">
    <a class="navbar-brand" [href]="homeUrl" [ngClass]="addMobileCss ? 'navbar-brand-mobile' : ''">
    </a>
    <a *ngIf="whitelable" class="navbar-brand navbar-brand-poweredby" [href]="homeUrl"
      [ngClass]="addMobileCss ? 'navbar-brand-mobile' : ''">
    </a>
    <button class="navbar-toggler" type="button" aria-controls="navbar-menu" aria-label="Toggle navigation"
      (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
      <i class="fas fa-bars navbar-toggler-icon"></i>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav ml-auto">
        <a *ngIf="!showCancelButton" class="nav-item nav-link" routerLink="/public" routerLinkActive="active">
          <span translate>Create Application</span>
        </a>
        <a class="nav-item nav-link" [href]="contactUsUrl" routerLinkActive="active">
          <span>Contact Us</span>
        </a>
        <ng-container *ngIf="duringCancelableApplication && showCancelButton">
          <div class="nav-item">
            <button class="plexe-btn-primary" (click)="logout()" translate>Logout</button>
          </div>
        </ng-container>
        <div class="nav-item mr-30 customer-login" *ngIf="showLoginButton && !duringCancelableApplication">
          <button class="plexe-btn-primary" routerLink="/login">Login</button>
        </div>
      </div>
    </div>
  </nav>
</div> -->

<header>
  <nav class="nav-container navbar navbar-expand-md navbar-light fixed-top mt-5">
    <div class="container-fluid gap-smallest">
      <div class="navbar-brand">
        <img style="cursor: pointer;" src="assets/icons/Logo.svg" alt="logo-icon" />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="d-none d-sm-block menu-items-block">
          <ul class="navbar-nav w-100 gap-smallest text-center">
            <li
              class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x3 border-sidemenu-light-blue curs-pointer outline-none d-flex align-items-center plexe-menu-header-1"
              routerLink="/public"
              (click)="onClickMenuItem()"
            >
              <a #menutabs class="nav-link">
                <span class="header-link">Create Application</span>
              </a>
            </li>

            <li
              class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x3 border-sidemenu-light-blue curs-pointer outline-none d-flex align-items-center plexe-menu-header-1"
              (click)="onClickMenuItem()"
            >
              <a class="nav-item nav-link" [href]="contactUsUrl">
                <span class="header-link">Contact Us</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="d-block d-sm-none">
          <ul class="navbar-nav w-100 gap-smallest text-center">
            <li
              class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x3 border-sidemenu-light-blue curs-pointer outline-none d-flex align-items-center plexe-menu-header-1"
              routerLink="/public"
              (click)="onClickMenuItem()"
            >
              <a #menutabs class="nav-link">
                <span class="header-link">Create Application</span>
              </a>
            </li>

            <li
              class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x3 border-sidemenu-light-blue curs-pointer outline-none d-flex align-items-center plexe-menu-header-1"
              (click)="onClickMenuItem()"
            >
              <a class="nav-item nav-link" [href]="contactUsUrl">
                <span class="header-link">Contact Us</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="d-flex align-items-center justify-content-between gap-small flex-column flex-md-row mt-3 mt-md-0 ml-auto"
          *ngIf="duringCancelableApplication && showCancelButton"
        >
          <button class="login-btn btn maxWidth" (click)="logout()">Logout</button>
        </div>

        <div
          class="d-flex align-items-center justify-content-between gap-small flex-column flex-md-row mt-3 mt-md-0 ml-auto"
          *ngIf="showLoginButton && !duringCancelableApplication"
        >
          <button class="login-btn btn maxWidth" routerLink="/login">Login</button>
        </div>
      </div>
    </div>
  </nav>
</header>
