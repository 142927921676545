import {
  ApplicationSettings,
  IncomeDetails,
  SecondaryApplicant,
  OfferDetails,
  InvoicePaymentCalculation,
  SupportDocumentDetails,
  Partner,
  Customer,
  DashboardAccounts,
  BankAccountConnectionSettings,
  PlexeOrganisationServiceModelsTransactionMatrix,
  BankAccount,
  Agent,
  ApplicationListProjection
} from '@app/core/proxy/Read/models';
import { StartApplicationRequest } from '@app/core/proxy/Write/models';
import { CustomerData } from './core/interfaces/i-customer-data';
import { PartnerData } from './core/interfaces/i-partner-data';
import { Offer } from './core/interfaces/i-offer';
import { AgentData } from './core/interfaces/i-agent-data';

export enum DataState {
  NotLoaded,
  Loading,
  Loaded,
  Error
}

export interface Data<T> {
  data: T;
  state: DataState;
}

export interface Filter {
  page?: number;
  orderBy?: string;
  orderbyDescending?: boolean;
  filterColumn?: string;
  filterValue?: string;
}

export interface Store {
  agent: Agent;
  agentData: AgentData;
  partner: Partner;
  customer: Customer;
  secondaryApplicant: SecondaryApplicant;
  customerData: CustomerData;
  partnerData: PartnerData;
  selectedApplicationId: string;
  application: ApplicationListProjection;
  applicantId: string;
  applicationSetting: ApplicationSettings;
  applicationIncomeDetails: IncomeDetails;
  applicationOfferDetails: OfferDetails;
  applicationInvoicePaymentCalculation: InvoicePaymentCalculation;
  applicationHasLoggedIn: boolean;
  systemMessage: string;
  isBankTransactionDataStale: boolean;
  bankTransactionDataStaleMessage: string;
  bankAccounts: Data<BankAccount[]>;
  offer: Offer;
  bankList: any[];
  loadingPromises: Promise<any>[];
  loadingCallbackUrl: string;
  applicationRegister: StartApplicationRequest;
  IDInfo: any;
  supportDocumentDetails: SupportDocumentDetails[];
  maxStepIndex: any[];
  accoutingSystem: string;
  isNextPage: boolean;
  dashboardAccounts: Data<DashboardAccounts>;
  bankAccountConnectionSettings: Data<BankAccountConnectionSettings>;
  missingBankStatementDocuments: Data<PlexeOrganisationServiceModelsTransactionMatrix[]>;
  platformSettings: any;
  agents: Data<Agent[]>;
  loadingPromiseCount: number;
  primaryApplicationCurrentProcess: string;
  secondaryApplicationCurrentProcess: string;
}

export const DataStore: any = {
  bankTransactionDataStaleMessage: null,
  isBankTransactionDataStale: false,
  systemMessage: null,
  primaryApplicationCurrentProcess: null,
  secondaryApplicationCurrentProcess: null,
  loadingPromiseCount: 0,
  selectedApplicationId: null,
  agent: null,
  agents: { data: null, state: DataState.NotLoaded },
  agentData: {
    notCompleteApplicationList: { data: null, state: DataState.NotLoaded },
    completedApplicationList: { data: null, state: DataState.NotLoaded },
    cancelledApplicationList: { data: null, state: DataState.NotLoaded },
    communications: { data: null, state: DataState.NotLoaded },
    advances: { data: null, state: DataState.NotLoaded },
    announcements: { data: null, state: DataState.NotLoaded },
    hasNewCommunication: false
  },
  dashboardAccounts: { data: null, state: DataState.NotLoaded },
  isNextPage: false,
  accoutingSystem: null,
  partner: null,
  customer: null,
  secondaryApplicant: null,
  applicationSetting: null,
  applicationOfferDetails: null,
  applicationInvoicePaymentCalculation: null,
  applicantId: '',
  customerData: {
    loans: null,
    readyApplications: null,
    displayedLoan: {
      displayMessage: { data: null, state: DataState.NotLoaded },
      questionMessage: { data: null, state: DataState.NotLoaded },
      notifications: { data: null, state: DataState.NotLoaded },
      settings: { data: null, state: DataState.NotLoaded },
      details: { data: null, state: DataState.NotLoaded },
      summary: { data: null, state: DataState.NotLoaded },
      transactions: {
        filter: {
          page: 0,
          orderBy: 'dateUTC',
          orderbyDescending: true,
          filterColumn: null,
          filterValue: null
        },
        data: null,
        dataSummary: null,
        total: 0,
        state: DataState.NotLoaded
      },
      debtors: { data: null, state: DataState.NotLoaded },
      advanceSummary: { data: null, state: DataState.NotLoaded },
      cashFlow: { data: null, state: DataState.NotLoaded },
      bankSetup: { data: true, state: DataState.NotLoaded },
      changeOfFacilityRequired: { data: true, state: DataState.NotLoaded },
      accountingInfo: {
        connectedToAccountingSystem: {
          data: null,
          state: DataState.NotLoaded
        },
        reconnectAccountingSystemUrl: {
          data: null,
          state: DataState.NotLoaded
        },
        isSyncing: false
      },
      incomeDetails: { data: null, state: DataState.NotLoaded },
      allContractDocuments: { data: null, state: DataState.NotLoaded },
      employeeMenuEnabled: false
    },
    displayedAdvance: {
      repaymentDetails: null,
      skipRepaymentDetails: null
    },
    hasNewCommunication: false
  },
  partnerData: {
    applicationList: { data: null, state: DataState.NotLoaded },
    commission: null,
    partnerCommunications: { data: null, state: DataState.NotLoaded },
    hasNewCommunication: false,
    completedApplicationList: { data: null, state: DataState.NotLoaded },
    notCompleteApplicationList: { data: null, state: DataState.NotLoaded },
    cancelledApplicationList: { data: null, state: DataState.NotLoaded },
    advances: { data: null, state: DataState.NotLoaded }
  },
  application: null,
  applicationIncomeDetails: null,
  applicationHasLoggedIn: true,
  bankAccounts: { data: null, state: DataState.NotLoaded },
  offer: null,
  bankList: null,
  loadingPromises: [],
  loadingCallbackUrl: null,
  applicationRegister: null,
  IDInfo: {
    medicare: null,
    driversLicence: null,
    ssn: null
  },
  supportDocumentDetails: [],
  bankAccountConnectionSettings: { data: null, state: DataState.NotLoaded },
  missingBankStatementDocuments: { data: null, state: DataState.NotLoaded },
  maxStepIndex: [{ value: 1 }, { value: 1 }],
  platformSettings: null
};
