<div
  class="container-fluid login-page-wrapper d-flex flex-column justify-content-between gap-large text"
  *ngIf="!isInit"
  [@fadeInOut]
>
  <div class="d-flex flex-column gap-smallest align-items-center mt-5">
    <img src="assets/icons/Logo.svg" alt="logo" class="logo" />
    <span class="title text-center">Empower your business to grow</span>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center gap-small">
    <form
      novalidate
      (ngSubmit)="login()"
      [formGroup]="loginForm"
      class="d-flex flex-column align-items-center gap-large w-100"
    >
      <div class="alert alert-danger mb-0" *ngIf="error">
        {{ error || 'Login name or password incorrect' }}
      </div>
      <div class="form-floating w-100 maxWidth">
        <input
          data-lpignore="true"
          type="email"
          class="form-control bg-transparent py-4 maxWidth"
          placeholder="Email"
          formControlName="username"
        />
        <label for="email">Email</label>
        <div
          class="text-danger position-absolute f-12"
          *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors"
        >
          <span *ngIf="loginForm.controls.username.errors.required">Email is required</span>
          <span *ngIf="loginForm.controls.username.errors.email">Please enter valid email id</span>
        </div>
      </div>
      <div class="form-floating w-100 maxWidth">
        <input
          data-lpignore="true"
          type="password"
          class="form-control bg-transparent py-4 maxWidth"
          placeholder="Password"
          formControlName="password"
        />
        <label for="password">Password</label>
        <div
          class="text-danger position-absolute f-12"
          *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors"
        >
          <span *ngIf="loginForm.controls.password.errors.required">Password is required</span>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary d-flex align-items-center gap-smallest"
        [disabled]="loginForm.invalid || isLoading || isRedirecting"
      >
        <span class="spinner-border spinner-border-sm" *ngIf="isLoading || isRedirecting"></span>Login with password
      </button>
    </form>
    <a routerLink="/login-sms">Login with SMS</a>
    <span class="text-center">Don't have an account? <a routerLink="/application">Sign up</a></span>
    <span class="text-center">Forgot your password? <a routerLink="/forgot-password">Forgot password</a></span>
  </div>
  <div class="d-flex flex-column footer justify-content-center align-items-center gap-smallest">
    <span>If you need help</span>
    <a [href]="contactUsUrl" target="_blank">Contact us <i class="bi bi-caret-right-fill"></i></a>
  </div>
</div>
