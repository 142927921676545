import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plexe-loader',
  templateUrl: './plexe-loader.component.html',
  styleUrls: ['./plexe-loader.component.scss']
})
export class PlexeLoaderComponent implements OnInit {
  @Input() isMainLoader: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
