import { ChangePasswordComponent } from './modules/public/change-password/change-password.component';
import { Routes } from '@angular/router';
import { LandingComponent } from '@app/components/landing/landing.component';
import { HomePageRedirectGuard } from '@app/core/guards/home-page-redirect.guard';
import { ThankyouComponent } from '@app/components/thankyou/thankyou.component';
import { PasswordResetComponent } from '@app/components/password-reset/password-reset.component';
import { LoginComponent } from '@app/components/login/login.component';
import { TwoFactorComponent } from '@app/components/two-factor/two-factor.component';
import { ForgotPasswordComponent } from '@app/modules/public/forgot-password/forgot-password.component';
import { GeneralErrorComponent } from '@app/components/errors/general/error.component';
import { Error404Component } from '@app/components/errors/error-404/error.component';
import { TimeoutErrorComponent } from '@app/components/errors/timeout-error/timeout-error.component';
import { ApplicationProcessingComponent } from '@app/components/errors/application-processing/application-processing.component';
import { LoadDataComponent } from '@app/core/load-data/load-data.component';
import { CustomerAuthenticationGuard } from '@app/modules/customer/customer-authentication.guard';
import { PartnerAuthenticationGuard } from '@app/modules/partner/partner-authentication.guard';
import { LogoutRedirectComponent } from '@app/components/logout-redirect/logout-redirect.component';
import { FirstLoginComponent } from './components/first-login/first-login.component';
import { LoginSmsComponent } from './components/login-sms/login-sms.component';
import { LoginGuard } from './components/login-guard/login-guard';
import { PasswordExpireComponent } from './components/password-expire/password-expire.component';
import { AgentAuthenticationGuard } from './modules/agent/agent-authentication.guard';
import { ApplicationModule } from '@app/components/application/application.module';
export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full', canActivate: [HomePageRedirectGuard] },
  { path: 'submit-success', component: ThankyouComponent, data: { title: 'Success' } },
  { path: 'password-reset', component: PasswordResetComponent, data: { title: 'Password Reset' } },
  { path: 'first-login', component: FirstLoginComponent, data: { title: 'First Login' } },
  { path: 'login', canActivate: [LoginGuard], component: LoginComponent, data: { title: 'Login' } },
  { path: 'login-sms', canActivate: [LoginGuard], component: LoginSmsComponent, data: { title: 'Login SMS' } },
  {
    path: 'password-expires',
    canActivate: [LoginGuard],
    component: PasswordExpireComponent,
    data: { title: 'Password Expires' }
  },
  { path: 'two-factor', component: TwoFactorComponent, data: { title: 'Two Factor Authentication' } },
  { path: 'landing', component: LandingComponent, data: { title: 'Loading...' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change Password' } },
  { path: 'error', component: GeneralErrorComponent, data: { title: 'Error' } },
  { path: 'error-404', component: Error404Component, data: { title: '404 Error' } },
  { path: 'error-timeout', component: TimeoutErrorComponent, data: { title: 'Timeout Error' } },
  { path: 'logout-redirect', component: LogoutRedirectComponent, data: { title: 'Logout Redirect' } },
  {
    path: 'application-processing',
    component: ApplicationProcessingComponent,
    data: { title: 'Application Processing' }
  },
  { path: 'loading', component: LoadDataComponent },
  {
    path: 'customer/:id/second-applicant',
    loadChildren: () => import('./modules/second-applicant/second-applicant.module').then(m => m.SecondApplicantModule),
    data: { reuse: true }
  },
  {
    path: 'customer/:id/second-applicant-facility-change',
    loadChildren: () =>
      import('./modules/second-applicant-facility-change/second-applicant-facility-change.module').then(
        m => m.SecondApplicantFacilityChangeModule
      ),
    data: { reuse: true }
  },
  {
    path: 'customer',
    canLoad: [CustomerAuthenticationGuard],
    loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
    data: { reuse: true }
  },
  {
    path: 'partner',
    canLoad: [PartnerAuthenticationGuard],
    loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule),
    data: { reuse: true }
  },
  // {
  //   path: 'public',
  //   loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  // },
  {
    path: 'public',
    redirectTo: 'application'
  },
  {
    path: 'public/new-application/:id',
    redirectTo: 'application/:id'
  },
  {
    path: 'loan',
    loadChildren: () => import('./modules/loan/loan.module').then(m => m.LoanModule)
  },
  {
    path: 'plaid',
    loadChildren: () => import('./modules/plaid/plaid.module').then(m => m.PlaidModule)
  },
  {
    path: 'agent',
    canLoad: [AgentAuthenticationGuard],
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule),
    data: { reuse: true }
  },

  {
    path: 'application',
    loadChildren: () => import('./components/application/application.module').then(m => m.ApplicationModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
