import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OriginationWriteApi } from '@app/core/proxy/Write/originationWriteApi';
import { markFormGroupTouched } from '@app/PlexeUtils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  state: any = [];
  isLoading = false;
  token: string;
  email: string;
  contactUsUrl = `${window['appConfig']['contactUsUrl']}`;
  constructor(
    private writeApi: OriginationWriteApi,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.state = JSON.parse(localStorage.getItem('userInfo'));
    if (!this.state) this.router.navigate(['/login']);
  }

  changePassword() {
    if (this.passwordForm.invalid) {
      markFormGroupTouched(this.passwordForm);
      return;
    }

    this.isLoading = true;
    const request = {
      userName: this.state.email,
      password: this.passwordForm.controls.newpassword.value,
      passwordConfirmation: this.passwordForm.controls.confirmpassword.value
    };
    this.writeApi
      .changePassword({ body: request })
      .then(_ => {
        this.toastrService.success('Password has been changed successfully.');
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.isLoading = false;
        this.toastrService.error('Failed to change password.');
      });
  }

  private initForm() {
    this.passwordForm = this.formBuilder.group(
      {
        newpassword: ['', Validators.required],
        confirmpassword: ['', Validators.required]
      },
      { validators: [this.checkPasswords] }
    );
  }

  private checkPasswords(group: FormGroup) {
    if (!group) {
      return;
    }
    const pass = group.controls.newpassword.value;
    const confirmPass = group.controls.confirmpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
