<div
  class="container-fluid login-page-wrapper d-flex flex-column justify-content-between gap-large text"
  *ngIf="!isInit"
  [@fadeInOut]
>
  <div class="d-flex flex-column gap-medium align-items-center mt-5">
    <img src="assets/icons/Logo.svg" alt="logo" class="logo" />
    <div class="d-flex flex-column align-items-center gap-smallest">
      <span class="title text-center">Forgot password</span>
      <span class="subtitle text-center">Reset your password</span>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center gap-small">
    <form
      novalidate
      *ngIf="!isSmsLogin"
      (ngSubmit)="forgotPassword()"
      [formGroup]="forgotPasswordForm"
      class="d-flex flex-column align-items-center gap-large w-100"
    >
      <div class="form-floating w-100 maxWidth">
        <input
          data-lpignore="true"
          type="email"
          class="form-control bg-transparent py-4 maxWidth"
          placeholder="Email"
          formControlName="email"
        />
        <label for="email">Email</label>
        <div
          class="text-danger position-absolute f-12"
          *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.errors"
        >
          <span *ngIf="forgotPasswordForm.controls.email.errors.required">Email is required</span>
          <span *ngIf="forgotPasswordForm.controls.email.errors.email">Please enter valid email id</span>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary d-flex align-items-center gap-smallest"
        [disabled]="forgotPasswordForm.invalid || isLoading"
      >
        <span class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>Send reset link
      </button>
    </form>
    <div class="d-flex flex-column gap-small" *ngIf="isSmsLogin" [@fadeInOut]>
      <div class="d-flex align-items-center gap-smallest">
        <span class="code-title">We sent an Email to </span>
        <span class="sentto">{{ forgotPasswordForm.value.email }}</span>
      </div>
      <code-input
        #codeInput
        [isCodeHidden]="false"
        [codeLength]="4"
        (codeCompleted)="onCodeCompleted($event)"
        [initialFocusField]="0"
        autocomplete="new-password"
      >
      </code-input>
      <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="failed" [@fadeInOut]>
        <div class="text-danger">
          <span>Invalid code. Please try again.</span>
        </div>
        <div>
          <span>Didn't receive the code?</span>
          <a class="btn-link" [class.disabled]="timeLeft > 0" (click)="resend()">Resend</a>
          <span *ngIf="timeLeft > 0"
            >in <span class="time-left">{{ timeDisplay }}</span></span
          >
        </div>
      </div>
      <!-- <div class="mt-3">
        <a class="btn-link" [class.disabled]="timeLeft > 0" (click)="resend()">Resend code</a>
        <span *ngIf="timeLeft > 0">in <span class="time-left">{{timeDisplay}}</span></span>
      </div> -->
    </div>

    <a routerLink="/login" (click)="loginWithPassword()">Back to login with password</a>
    <a routerLink="/login-sms">Login via SMS</a>
  </div>
  <div class="d-flex flex-column footer justify-content-center align-items-center gap-smallest">
    <span>If you need help</span>
    <a [href]="contactUsUrl" target="_blank">Contact us <i class="bi bi-caret-right-fill"></i></a>
  </div>
  <!-- <app-plexe-loader *ngIf="isLoading"></app-plexe-loader> -->
</div>
