import { Component, HostBinding, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() withPublicTemplate = true;
  @Input() noMenuItem = false;
  message = '';
  showButton = false;
  @HostBinding('class.show') isLoading = false;
  customeProgressBarValue: number; //customer progress bar, please, don't delete this
  progressBarData: { value: number; max: number } = null;
  messageSubscription: Subscription;
  loadingSubscription: Subscription;
  showButtonSubscription: Subscription;
  progressBarSubscription: Subscription;
  constructor(private loaderService: LoaderService, private appNavigatorService: AppNavigatorService) {
    this.messageSubscription = this.loaderService.getMessage().subscribe(message => {
      this.message = message;
    });
    this.loadingSubscription = this.loaderService.getStatus().subscribe(loading => {
      this.isLoading = loading;
      if (!loading) {
        this.message = '';
        this.progressBarData = null;
      }
    });
    this.showButtonSubscription = this.loaderService.getShowButton().subscribe(showButton => {
      this.showButton = showButton;
    });
    this.progressBarSubscription = this.loaderService.getProgressBar().subscribe(data => {
      console.log('data => ', data);
      var res = {
        max: data.max * 100,
        value: data.value * 100
      };
      this.progressBarData = res;
      this.customeProgressBarValue = data.value * (100 / data.max); //customer progress bar, please, don't delete this
    });
  }

  ngOnInit() {
    this.progressBarData = null;
    this.customeProgressBarValue = null; //customer progress bar, please, don't delete this
  }
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.showButtonSubscription.unsubscribe();
  }

  navHome() {
    this.appNavigatorService.navigateApplicationUrlByStatus();
  }
}
